import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@restart/ui/esm/Button";
import { Link } from "react-router-dom";

import HeaderBooks from "../../components/headerBooks";
import * as EbooksServices from "../../services/api/EbooksServices";
import * as EbooksCategoriesServices from "../../services/api/EbooksCategoriesServices";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const useStyles = makeStyles({
  sectionButton: {
    margin: "10px",
    padding: "10px 20px",
    backgroundColor: "#54B5CF",
    color: "#fff",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#0facc4",
    },
  },
  activeSection: {
    backgroundColor: "#0facc4",
  },
  botaoPrimeiro: {
    marginRight: "40px",
    backgroundColor: "#54B5CF",
    borderColor: "#54B5CF",
    borderRadius: "25px",
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    height: "50px",
    margin: "40px 0",
    padding: "10px 60px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#0facc4",
    },
  },
});

export default function Books() {
  const classes = useStyles();
  const [playlists, setPlaylists] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [activeSection, setActiveSection] = React.useState("Todos");
  const [categories, setCategories] = React.useState([]);


  React.useEffect(() => {
    (async () => {
      const resp = await EbooksCategoriesServices.findAll(); 
 
      const formattedCategories = ["Todos", ...resp.data.map((category) => category.title)];

      setCategories(formattedCategories);
    })();
  }, []);


  React.useEffect(() => {
    (async () => {
      const resp = await EbooksServices.findAll();
      setPlaylists(resp.data);
      setLoading(false);
    })();
  }, []);
  
  const filterEbooks = (section) => {
    if (section === "Todos") return playlists;
    return playlists.filter((ebook) => ebook?.category?.startsWith(section));
  };

  return (
    <div>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="error" />
        </Box>
      )}
      <HeaderBooks />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {categories.map(
          (section) => (
            <button
              key={section}
              className={`${classes.sectionButton} ${
                activeSection === section ? classes.activeSection : ""
              }`}
              onClick={() => setActiveSection(section)}
            >
              {section}
            </button>
          )
        )}
      </div>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        style={{ padding: "20px" }}
      >
        {filterEbooks(activeSection)?.map((value, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Link
              to={`/admin/profissional/read?path=${value?.path}&id=${value?.id}`}
              style={{ textDecoration: "none" }}
            >
              <div
                style={{
                  cursor: "pointer",
                  width: "95%",
                  height: "400px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundImage: `url(${value?.thumbnail})`,
                  backgroundSize: "contain",
                  border: "15px solid #9BDBE9",
                  borderRadius: "20px",
                }}
              ></div>
              <small style={{ color: "#3C6C98" }}>{value?.title}</small>
            </Link>
          </Grid>
        ))}
      </Grid>

      <Button
        className={classes.botaoPrimeiro}
        href="/admin/profissional/perfil"
      >
        VOLTAR
      </Button>
    </div>
  );
}
